/**
 * function to check the cookie value of legalage
 * @returns {boolean} is true if the user is of legal age
 */
function checkForLegalAgeCookie() {
    let legalAge;
    let name = 'legalAge=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(name) === 0) {
            legalAge = cookie.substring(name.length + 1, cookie.length);
        }
    }
    return legalAge === 'true';
}

/** Show a sticky banner at the bottom of the page for consenting with the site tracking policy
* @param {boolean} show depending if the banner should be hidden or not
* @param {any} bannerComponent banner component that should be hidden or not
*/
function showConsentBanner() {
    let modal = $('#containsAlcoholModal');
    if (modal.hasClass('isShown') && !checkForLegalAgeCookie()) {
        $('#containsAlcoholModal').modal('show');
    }
}

$(document).ready(function () {
    showConsentBanner();
    $('#ageCheck').on('click', function () {
        // this sets the cookie to the current selected value
        let name = 'legalAge=';
        let value = $('#ageCheck').is(':checked');
        const date = new Date();
        date.setTime(date.getTime() + (30 * 60 * 1000));
        let expires = 'expires=' + date.toUTCString();
        document.cookie = name + '=' + value + ';' + expires + ';path=/';
    });
    $('#redirectBack').on('click', function (element) { // default redirects to search-show => if the check passes close the modal
        if (checkForLegalAgeCookie()) {
            element.preventDefault();
            $('#containsAlcoholModal').modal('toggle');
        }
    });
});
